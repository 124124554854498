(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/tickets/assets/javascripts/tickets.js') >= 0) return;  svs.modules.push('/templates/tickets/assets/javascripts/tickets.js');
'use strict';

if (svs.tickets && svs.tickets.data && svs.tickets.data.templateConfig) {
  let branding = svs.tickets.data.templateConfig.branding;
  if (!svs.tickets.data.templateConfig.hideNav) {
    const clickCallback = e => {
      const event = {
        category: "".concat(branding, "_nav"),
        action: 'click_secondaryMenu',
        opt_label: e.title || ''
      };
      svs.components.analytics.trackEvent(event);
      svs.core.log.getLogger('ui:items-menu').debug('tracking click_secondaryMenu event:', event);
      location.href = e.href;
    };
    const options = {
      el: "js-items-menu-".concat(branding),
      class: "js-items-menu-".concat(branding),
      useScrollHelpers: svs.core.detect.formfactor.desktop(),
      useScrollFaders: true,
      showScrollbar: false,
      scrollToSelected: true,
      clickCallback
    };
    new svs.ui.ItemsMenu(options);
  }
  const headerLogo = document.querySelector('.js-top-center-content');
  headerLogo === null || headerLogo === void 0 ? void 0 : headerLogo.addEventListener('click', () => {
    branding = branding === 'e-instant' ? 'fler lotter' : branding;
    svs.components.analytics.trackEvent({
      category: 'Navigation',
      action: 'logo click',
      opt_label: branding.toUpperCase()
    });
  });
}

 })(window);